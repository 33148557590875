import React from 'react';
import { Link, graphql, useStaticQuery } from 'gatsby';
import { Seo } from '../../../modules/common/components/seo';
import { DashboardLayout } from '../../../modules/layout/components/dashboard-layout';
import { StaticImage } from 'gatsby-plugin-image';
import { SectionHeader } from '../../../modules/common/components/section-header';
import { Employee } from '../../../modules/cs/common/components/employee';
import ScrollspyNav from 'react-scrollspy-nav';

import '../../generic-page.scss';
import { Table } from 'react-bootstrap';

const CSRearmPage: React.FC = () => {
  const data = useStaticQuery(graphql`
    query RearmEmployeesPageQuery {
      rearmEmployees: allContentfulEmployee(
        sort: { fields: krReleaseDate, order: ASC }
        filter: { isRearmed: { eq: true } }
      ) {
        nodes {
          id
          slug
          isRearmed
          krReleaseDate
        }
      }
    }
  `);
  return (
    <DashboardLayout className={'generic-page'} game="cs">
      <ul className="breadcrumb">
        <li>
          <Link to="/counter-side">Counter Side</Link>
        </li>
        <li className="divider">/</li>
        <li>
          <Link to="/counter-side/guides">Guides</Link>
        </li>
        <li className="divider">/</li>
        <li>Rearm system</li>
      </ul>
      <div className="page-header">
        <div className="page-icon">
          <StaticImage
            src="../../../images/counterside/categories/guide_rearm.png"
            alt="Rearm system"
          />
        </div>
        <div className="page-details">
          <h1>Rearm system</h1>
          <h2>This guide explains the details of the Rearm system.</h2>
          <p>
            Last updated: <strong>17/03/2024</strong>
          </p>
        </div>
      </div>
      <div className="page-content">
        <div className="guide-with-columns">
          <div className="left-content">
            <div className="section-scroll" id="section-0">
              <SectionHeader title="Overview" />
              <StaticImage
                src="../../../images/counterside/generic/rearm_guide_0.jpg"
                alt="Rearm"
              />
              <p>
                When the Rearmament System was first released in the KR region,
                it caused a massive backlash as the cost of rearming one
                employee was way too high, and even whales were complaining
                about it. As a result, the system was redesigned and is now
                pretty decent cost-wise.
              </p>
              <p>Here's the overview of the Rearm system:</p>
              <ul>
                <li>
                  Employees need to be limit broken to level 110 before they can
                  be rearmed
                </li>
                <li>
                  After starting the rearm process, the base version of the
                  employee is replaced with the rearmed version at level 1
                  (Loyalty Level, Lifetime Contract, and current Skill Levels
                  are kept).
                </li>
                <li>
                  Rarity of the Rearmed employee is increased by one (max SSR).
                </li>
                <li>
                  Rearmed employees get new art and skills, but their class and
                  cost can also change.
                </li>
                <li>The maximum level of skills is increased from 5 to 10.</li>
                <li>
                  Rearmed employees gain Leader skills. Leader skills are
                  activated when the employee is used as the Leader in their
                  squad.
                </li>
                <li>
                  Rearmed employees can equip the skins and Exclusive Equipment
                  of their base version.
                </li>
                <li>
                  Rearmed employees are treated as base employees for various
                  mission clear conditions.
                </li>
              </ul>
              <div
                className="fuse-ad-placeholder"
                data-fuse="22844297253"
              ></div>
            </div>
            <div className="section-scroll" id="section-1">
              <SectionHeader title="How to Rearm someone?" />
              <StaticImage
                src="../../../images/counterside/generic/rearm_guide_1.jpg"
                alt="Rearm"
              />
              <p>
                To rearm an employee, simply go to your HQ. You will find the
                option to do it in the lab. There, you will be greeted with the
                list of available Rearms (look at the image above). Once you
                select an employee to rearm, you will have to pay the 'fee' to
                actually do it.
              </p>
              <p>
                Here’s a table showing the resources needed to rearm an employee
                depending on their base rarity:
              </p>
              <Table striped bordered responsive>
                <thead>
                  <tr>
                    <th>Rarity</th>
                    <th>Credits</th>
                    <th>Rearm Data</th>
                    <th>Special Rearm Data</th>
                    <th>Cores</th>
                    <th>Special Cores</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>R</td>
                    <td>2 500 000</td>
                    <td>35</td>
                    <td>-</td>
                    <td>140</td>
                    <td>30</td>
                  </tr>
                  <tr>
                    <td>SR</td>
                    <td>3 500 000</td>
                    <td>50</td>
                    <td>-</td>
                    <td>200</td>
                    <td>45</td>
                  </tr>
                  <tr>
                    <td>SSR</td>
                    <td>5 000 000</td>
                    <td>-</td>
                    <td>80</td>
                    <td>270</td>
                    <td>60</td>
                  </tr>
                </tbody>
              </Table>
              <p>
                After you rearm someone, they will be at level 1 again, but you
                will not have to limit break or limit fusion them to get them
                back to level 110. You simply have to feed them EXP. Here’s the
                amount of EXP needed:
              </p>
              <ul>
                <li>N Rarity - 1 275 841</li>
                <li>R Rarity - 1 467 217</li>
                <li>SR Rarity - 1 722 385</li>
                <li>SSR Rarity - 3 253 395</li>
              </ul>
              <p>
                As for their new rearm skills, they will cost red skill books.
                No unit data is needed.
              </p>
            </div>
            <div className="section-scroll" id="section-2">
              <SectionHeader title="Data Transmission" />
              <StaticImage
                src="../../../images/counterside/generic/rearm_guide_2.jpg"
                alt="Rearm"
              />
              <p>
                Data Transmission is a new system that allows you to consume not
                needed SR and SSR rarity employees and obtain Rearmament Data.
              </p>
              <p>
                You can only transmit employees who are SR rarity or above. You
                will receive normal Rearmament Data when transmitting SR
                employees and Special Rearmament Data when transmitting SSR
                employees.
              </p>
              <p>
                The amount of Rearmament Data also varies based on how you
                obtained the employee. SSR employees from gacha give 20 Special
                Rearmament Data while SSR employees from elsewhere give only 2
                Special Rearmament Data; SR employees from gacha give 2 normal
                Rearmament Data while SR employees from elsewhere give only 1
                normal Rearmament Data.
              </p>
              <div
                className="fuse-ad-placeholder"
                data-fuse="22844297016"
              ></div>
            </div>
            <div className="section-scroll" id="section-3">
              <SectionHeader title="Available Rearms" />
              <p>
                Here is a list of all currently available Rearmed characters.
              </p>
              <div className="employee-container">
                {data.rearmEmployees.nodes.map((employee, index) => {
                  return (
                    <Employee
                      slug={employee.slug}
                      mode="icon"
                      enablePopover
                      key={index}
                    />
                  );
                })}
              </div>
            </div>
          </div>
          <div className="right-sidebar with-sticky">
            <ScrollspyNav
              scrollTargetIds={[
                'section-0',
                'section-1',
                'section-2',
                'section-3'
              ]}
              activeNavClass="is-active"
              scrollDuration="500"
              headerBackground="true"
              offset={100}
            >
              <h6>Jump to</h6>
              <ul className="list-unstyled">
                <li>
                  <a href="#section-0">Overview</a>
                </li>
                <li>
                  <a href="#section-1">How to Rearm someone?</a>
                </li>
                <li>
                  <a href="#section-2">Data Transmission</a>
                </li>
                <li>
                  <a href="#section-3">Available Rearms</a>
                </li>
              </ul>
            </ScrollspyNav>
            <div className="fuse-ad-placeholder" data-fuse="22844297250"></div>
          </div>
        </div>
      </div>
    </DashboardLayout>
  );
};

export default CSRearmPage;

export const Head: React.FC = () => (
  <Seo
    title="Rearm system | Counter Side | Prydwen Institute"
    description="This guide explains the Rearm system in Counter Side."
    game="cs"
  />
);
